import ROLES from "../enums/roles";
import {
  BsCardList,
  BsCalendar4Event,
  BsBookmark,
  BsBookmarkPlus,
  BsBuildings,
  BsClipboardData,
  BsWindow,
  BsWindowPlus,
  BsClipboard2Plus,
  BsClipboardCheck,
  BsBarChartLine,
} from "react-icons/bs";
import { GiDeerHead } from "react-icons/gi";
import { HiOutlineUsers } from "react-icons/hi2";
import { TbCalendarDollar } from "react-icons/tb";
import { LuBook } from "react-icons/lu";

export const getSidebarRoutes = () => {
  return [
    {
      label: "Lovci",
      roles: ROLES.all(),
      children: [
        {
          route: "/hunting-cards",
          label: "Lovne karte",
          roles: ROLES.all(),
          icon: <BsWindow size={15} />,
        },
        {
          route: "/hunting-card-orders",
          label: "Zahtevi za lovne karte",
          roles: [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.User,
            ROLES.Agency,
            ROLES.Moderator,
          ],
          icon: <BsCardList size={15} />,
        },
        {
          route: "/exams",
          label: "Ispiti",
          roles: [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.User,
            ROLES.Moderator,
            ROLES.Ranger,
            ROLES.Inspector,
            ROLES.Examiner,
          ],
          icon: <LuBook size={15} />,
        },
      ],
    },
    {
      label: "Gazdovanje lovištem",
      roles: [
        ROLES.Superadmin,
        ROLES.Admin,
        ROLES.User,
        ROLES.Inspector,
        ROLES.Ranger,
        ROLES.Moderator,
      ],
      children: [
        {
          route: "/hunting-plan",
          label: "Godišnji plan",
          roles: [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.User,
            ROLES.Inspector,
            ROLES.Ranger,
            ROLES.Moderator,
          ],
          icon: <BsCalendar4Event size={15} />,
        },
        {
          route: "/hunting-records",
          label: "Evidencija odstrela",
          roles: [ROLES.Superadmin, ROLES.Admin, ROLES.User, ROLES.Moderator],
          icon: <BsClipboardData size={15} />,
        },
        {
          route: "/stamp-orders",
          label: "Zahtevi za markice",
          roles: [ROLES.Superadmin, ROLES.Admin, ROLES.User, ROLES.Moderator],
          icon: <BsBookmark size={15} />,
        },
      ],
    },
    {
      label: "Magacin",
      roles: [ROLES.Superadmin, ROLES.Admin, ROLES.Moderator],
      children: [
        {
          route: "/warehouse-stamps",
          label: "Markice",
          roles: [ROLES.Superadmin, ROLES.Admin, ROLES.Moderator],
          icon: <BsBookmarkPlus size={15} />,
        },
        {
          route: "/warehouse-hunting-cards",
          label: "Lovne karte",
          roles: [ROLES.Superadmin, ROLES.Admin, ROLES.Moderator],
          icon: <BsWindowPlus size={15} />,
        },
      ],
    },
    {
      label: "Izveštaj",
      roles: [ROLES.Superadmin, ROLES.Admin, ROLES.Moderator],
      children: [
        {
          route: "/reports/hunting-card",
          label: "Lovne karte",
          roles: [ROLES.Superadmin, ROLES.Admin, ROLES.Moderator],
          icon: <BsClipboard2Plus size={15} />,
        },
        {
          route: "/reports/hunting-ground",
          label: "Lovišta",
          roles: [ROLES.Superadmin, ROLES.Moderator],
          icon: <BsClipboardCheck size={15} />,
        },
        {
          route: "/reports/compensations",
          label: "Tabela naknade",
          roles: [ROLES.Superadmin, ROLES.Moderator],
          icon: <TbCalendarDollar size={17} />,
        },
        {
          route: "/reports/shooting-statistics",
          label: "Statistika odstrela",
          roles: [ROLES.Superadmin, ROLES.Moderator],
          icon: <BsBarChartLine size={15} />,
        },
      ],
    },
    {
      label: "Administracija",
      roles: [
        ROLES.Superadmin,
        ROLES.Admin,
        ROLES.User,
        ROLES.Inspector,
        ROLES.Ranger,
        ROLES.Moderator,
      ],
      children: [
        {
          route: "/organizations",
          label: "Korisnici lovišta/Agencije",
          roles: [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.Inspector,
            ROLES.Moderator,
          ],
          icon: <BsBuildings size={15} />,
        },
        {
          route: "/users",
          label: "Nalozi",
          roles: [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.Inspector,
            ROLES.Moderator,
          ],
          icon: <HiOutlineUsers size={15} />,
        },
        {
          route: "/hunting-grounds",
          label: "Lovišta",
          roles: [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.User,
            ROLES.Inspector,
            ROLES.Ranger,
            ROLES.Moderator,
          ],
          icon: <GiDeerHead size={15} />,
        },
      ],
    },
  ];
};
