import PageLayout from "@/components/layout/PageLayout";
import Table from "@/components/table/Table";
import Utils from "@/common/utils/Utils";
import { useQuery } from "@/common/hooks/useQuery";
import { useEffect, useState } from "react";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./shooting-statistics-fields";
import { ReportsService } from "@/common/API/ReportsService";
import { ShootingReportResponse } from "@/common/types/Reports";
import useDropdownData from "@/common/hooks/useDropdownData";
import { DropdownData } from "@/common/types/Form";
import { AnimalsService } from "@/common/API/AnimalsService";
import { HuntingGroundsService } from "@/common/API/HuntingGroundsService";

const ShootingStatisticsList = () => {
  const [data, setData] = useState<ShootingReportResponse | []>([]);
  const [seasons, setSeasons] = useState<DropdownData[]>([]);
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });
  const { associations, organizations } = useDropdownData({
    hasAgency: true,
    query,
  });
  const [animals, setAnimals] = useState<DropdownData[]>([]);
  const [huntingGrounds, setHuntingGrounds] = useState<DropdownData[]>([]);

  const getAllFields = async () => {
    let response = await AnimalsService.all();
    setAnimals(response?.data?.data || []);

    response = await HuntingGroundsService.all();
    setHuntingGrounds(response?.data?.data || []);
  };

  const getShootingReports = async () => {
    // console.log("Query: ", query)
    const response = await ReportsService.shooting_statistics(
      Utils.formatMultipleOptionsQueryString(query),
    );

    const availableSeasons = response?.data?.data?.available_seasons || [];
    const formattedSeasons: DropdownData[] = availableSeasons.map(
      (season: string) => ({
        name: season,
        id: season,
      }),
    );
    setSeasons(formattedSeasons);

    setData(response?.data?.data?.data || []);
  };

  useEffect(() => {
    getAllFields();
  }, []);

  useEffect(() => {
    getShootingReports();
  }, [query]);

  return (
    <PageLayout title="Statistika odstrela">
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(
          associations,
          organizations,
          animals,
          huntingGrounds,
          seasons,
        )}
        filterQueries={filterQueries}
        hideActions={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
      />
    </PageLayout>
  );
};
export default ShootingStatisticsList;
