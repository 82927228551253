import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "./ui/dropdown";
import InputRequiredTooltip from "./forms/InputRequiredTooltip";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { FieldInputProps, FieldProps, FormikProps } from "formik";
import { useState } from "react";

type MultipleSelectDropdownProps = {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  options: {
    name: string;
    id: number;
  }[];
  value: string[];
  className?: string;
  onChange: () => void;
  submit?: () => void;
};

type MultipleSelectFormProps = FieldProps<any> & MultipleSelectDropdownProps;

const MultiSelectDropdown = ({
  field,
  form,
  label,
  disabled,
  required,
  placeholder,
  options,
  className,
  onChange,
  submit,
}: MultipleSelectFormProps) => {
  const error = form?.errors?.[field?.name!];
  const [filteredOptions, setFilteredOptions] = useState(options);
  console.log("filteredOptions", filteredOptions);

  const handleSelectChange = (value: number) => {
    if (field?.value?.includes(value)) {
      const newValue = field?.value?.filter((el: any) => el !== value);
      form?.setFieldValue(field?.name, newValue);
    } else {
      form?.setFieldValue(field?.name, [...field?.value, value]);
    }
  };

  const isOptionSelected = (value: number): boolean => {
    return field?.value?.includes(value) ? true : false;
  };

  function formatSelectedItemsString(
    options: { name: string; id: number }[],
    values: string[],
  ): string {
    const labels: string[] = [];

    for (const value of values) {
      for (const obj of options) {
        if (obj?.id === Number(value)) {
          labels.push(obj?.name);
        }
      }
    }

    const selectedItemsString =
      labels?.length >= 3
        ? `${labels?.length} izabranih rola`
        : labels.join(", ");

    return selectedItemsString;
  }

  return (
    <div className={`${className} relative`}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="relative">
            {label && (
              <div className="flex flex-row items-center space-x-1">
                <label className="mb-2 block text-xs text-label">{label}</label>
                {required && <InputRequiredTooltip label="Polje je obavezno" />}
              </div>
            )}
            <div className="absolute bottom-3 right-3">
              <CaretSortIcon className="h-4 w-4 opacity-50" />
            </div>
            <input
              disabled={true}
              value={
                field?.value?.length === 0
                  ? ""
                  : formatSelectedItemsString(options, field?.value)
              }
              className={`w-full cursor-pointer rounded-lg border border-gray-300 bg-background p-2.5 text-xs text-label placeholder:text-black focus:border-primary ${error && !disabled ? "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500" : ""}`}
              placeholder={placeholder}
            />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          onCloseAutoFocus={(e) => e.preventDefault()}
          className="max-h-80 overflow-y-auto"
        >
          <div className="p-2">
            <input
              type="text"
              placeholder="Search..."
              className="w-full rounded-lg border border-gray-300 p-2 text-xs"
              onChange={(e) => {
                const searchValue = e.target.value.toLowerCase();
                setFilteredOptions(
                  options.filter((option) =>
                    option.name.toLowerCase().includes(searchValue),
                  ),
                );
              }}
            />
          </div>
          {filteredOptions?.map(
            (
              value: MultipleSelectDropdownProps["options"][0],
              index: number,
            ) => {
              return (
                <DropdownMenuCheckboxItem
                  onSelect={(e) => e.preventDefault()}
                  key={index}
                  checked={isOptionSelected(value.id)}
                  onCheckedChange={() => {
                    handleSelectChange(value.id);
                    onChange?.();
                    submit?.();
                  }}
                >
                  {value.name}
                </DropdownMenuCheckboxItem>
              );
            },
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {disabled ? null : (
        <p className="absolute text-xs text-error">
          {error ? (error as string) : ""}
        </p>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
