import { ShootingReport } from "@/common/types/Reports";
import { DropdownData } from "@/common/types/Form";
import Dropdown from "@/components/forms/Dropdown";
import DropdownSearch from "@/components/forms/DropdownSearch";
import Text from "@/components/Text";
import MultiSelectDropdown from "@/components/MultipleSelectDropdown";
import MultipleSelect from "@/components/forms/MultipleSelect";

export const tableColumns = [
  {
    title: "Vrsta divljači",
    dataIndex: ["animal", "name"],
    key: ["animal", "name"],
  },
  {
    title: "Mužjak",
    dataIndex: "total_male",
    key: "total_male",
    render: (data: any, index: number) => {
      
      return <Text variant="table">{data?.total_male === null ? '-' : data?.total_male}</Text>;
    },
  },
  {
    title: "Ženka",
    dataIndex: "total_female",
    key: "total_female",
    render: (data: any, index: number) => {
      
      return <Text variant="table">{data?.total_female === null ? '-' : data?.total_female}</Text>;
    },
  },
  {
    title: "Podmladak",
    dataIndex: "total_ofspring",
    key: "total_ofspring",
    render: (data: any, index: number) => {
      
      return <Text variant="table">{data?.total_offspring === null ? '-' : data?.total_offspring}</Text>;
    },
  },
  {
    title: "Ukupno",
    dataIndex: "total",
    key: "total",
    render: (data: any, index: number) => {
      
      return <Text variant="table">{data?.total === null ? Number(data?.total_male)+Number(data?.total_female)+Number(data?.total_offspring) : data?.total}</Text>;
    },
  },
];

  export const filterFields = (
    associations? : DropdownData[],
    organizations?: DropdownData[],
    animals?: DropdownData[],
    huntingGrounds?: DropdownData[],
    seasons?: DropdownData[]
  ) => {
    console.log("Associations: ", associations)
    console.log("Organizations: ", organizations)
    console.log("Animals: ", animals)
    console.log("Hunting grounds: ", huntingGrounds)
    console.log("Seasons: ", seasons)
    return [
      {
        controlType: MultiSelectDropdown,
        className: "md:col-span-2 col-span-12",
        options: {
          label: "Korisnik",
          name: "organization_id",
          placeholder: "Izaberi korisnika lovišta",
          options: organizations ?? [],
          value: '',
        },
      },
      {
        controlType: MultiSelectDropdown,
        className: "md:col-span-2 col-span-12",
        options: {
          label: "Loviste",
          name: "hunting_ground_id",
          placeholder: "Izaberi lovište",
          options: huntingGrounds ?? [],
          value: "",
        },
      },
      {
        controlType: MultiSelectDropdown,
        className: "md:col-span-2 col-span-12",
        options: {
          label: "Savez",
          name: "association_id",
          placeholder: "Izaberi savez",
          options: associations ?? [],
          value: "",
        },
      },
      {
        controlType: MultiSelectDropdown,
        className: "md:col-span-2 col-span-12",
        options: {
          label: "Sezona",
          name: "season",
          placeholder: "Izaberi sezonu",
          options: seasons ?? [],
          value: "",
        },
      },
      {
        controlType: MultiSelectDropdown,
        className: "md:col-span-2 col-span-12",
        options: {
          label: "Divljac",
          name: "animal_id",
          placeholder: "Izaberi divljač",
          options: animals ?? [],
          value: "",
        },
      },
    ];
  };
  
  export const filterQueries = {
    organization_id: "",
    hunting_ground_id: "",
    association_id: "",
    season: "",
    animal_id: "",
  };