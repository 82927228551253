import CONSTANTS from "../config/constants";
import { Role } from "../types/User";

const Utils = {
  JSON: (json: any) => {
    return (
      <pre className="overflow-hidden rounded-md bg-gray-800 p-3 text-yellow-300 shadow-2xl">
        {JSON.stringify(json, null, 2)}
      </pre>
    );
  },
  getObjectKeyValue: (object: any) => {
    const keys = Object.keys(object).filter(
      (k) => typeof object[k] === "number",
    );
    const values = keys.map((k) => object[k]);

    return [keys, values];
  },
  htmlToText(htmlString: string) {
    let span = document.createElement("span");
    span.innerHTML = htmlString;
    return span.textContent || span.innerText;
  },
  convertTime(inputTime: string) {
    const date = new Date(inputTime);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  },
  hasPermission(userRoles?: Role[], allowedRoles?: number[]) {
    return userRoles?.some((role: Role) => allowedRoles?.includes(role?.id));
  },
  getMonthString(dateString: string) {
    const date = new Date(dateString);
    const monthIndex = date.getUTCMonth();
    const monthName = CONSTANTS.months[monthIndex];
    const dayName = date.getUTCDate();

    return `${dayName}. ${monthName}`;
  },
  formatQueryString(obj: Record<string, any>) {
    const queryString = Object.keys(obj)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(obj[key].toString())}`,
      )
      .join("&");

    return queryString;
  },
  formatMultipleOptionsQueryString(obj: Record<string, any>) {
    const queryString = Object.keys(obj)
      .map((key) => {
        if (Array.isArray(obj[key])) {
          return obj[key]
            .map((value: any) => `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`)
            .join("&");
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key].toString())}`;
        }
      })
      .join("&");
    return queryString;
  },
  debounce(callback: () => void, delay: number = 300) {
    let timeout: NodeJS.Timeout;

    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        callback();
      }, delay);
    };
  },
  formatNumber(number?: number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  openDocument(documentLink?: string) {
    const newWindow = window.open(
      documentLink,
      "_blank",
      "noopener,noreferrer",
    );
    if (newWindow) newWindow.opener = null;
  },
};

export default Utils;
