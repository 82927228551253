import TableUtils from "@/common/utils/TableUtils";
import TablePagination from "./TablePagination";
import { useEffect, useRef, useState } from "react";
import { TableProps, ITableColumn } from "@/common/types/Table";
import FormFactory from "../forms/FormFactory";
import TableColumnHeaderItem from "./TableColumnHeaderItem";
import TableSkeleton from "../skeleton/TableSkeleton";
import { CiFilter } from "react-icons/ci";
import { GoChevronLeft } from "react-icons/go";
import { LiaSortSolid } from "react-icons/lia";
import { LiaSortDownSolid } from "react-icons/lia";
import { LiaSortUpSolid } from "react-icons/lia";
import tableActionButtons from "./TableActionButtons";
import TooltipWrapper from "../TooltipWrapper";
import { Button } from "../ui/button";
import { RxValueNone } from "react-icons/rx";
import TableSortHeaderItem from "./TableSortHeaderItem";
import { FormikProps } from "formik";
import { GrPowerReset } from "react-icons/gr";

function Table(props: TableProps) {
  const {
    columns,
    filterFields,
    data,
    tableHeaderContent,
    filterQueries,
    onEdit,
    onDelete,
    onPrint,
    hideActions,
    hideEdit,
    hideDelete,
    hidePrint,
    hidePagination,
    actionButtons,
    selectedRows,
    selectAll,
    query,
    setQuery,
    setFilters,
    paginationMethods,
    customComponent,
  } = props;

  const paginationData = data?.pagination;
  const [showFilters, setShowFilters] = useState(true);
  const [columnsData, setColumnsData] = useState<ITableColumn[]>([]);

  const formRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    setColumnsData(columns);
  }, [columns]);

  useEffect(() => {
    for (let queryKey in filterQueries) {
      formRef?.current?.setFieldValue(queryKey, query?.[queryKey]);
    }
  }, [showFilters, formRef?.current]);

  useEffect(() => {
    setColumnsData((prevState: ITableColumn[]) =>
      prevState.map((el) => {
        if (el && el.sortOptions) {
          el.sortOptions.sortDirection =
            el.dataIndex === query?.sort ? query?.order : "";
        }

        return el;
      }),
    );
  }, [query]);

  const onChangeColumnSort = (sortDirection: string, sortParam?: string) => {
    setQuery?.((prevState: Record<string, string>) => {
      return {
        ...prevState,
        sort: String(sortParam),
        order: String(sortDirection),
      };
    });
  };

  const renderColumnHeader = (column: ITableColumn, index: number) => {
    switch (column?.sortOptions?.sortDirection) {
      case "":
        return (
          <TableColumnHeaderItem
            content={
              <TableSortHeaderItem
                title={column?.title}
                icon={<LiaSortSolid size={15} />}
                onClick={() =>
                  onChangeColumnSort("desc", column?.sortOptions?.sortParam)
                }
              />
            }
            key={index}
          />
        );
      case "desc":
        return (
          <TableColumnHeaderItem
            content={
              <TableSortHeaderItem
                title={column?.title}
                icon={<LiaSortDownSolid size={15} className="text-primary" />}
                onClick={() =>
                  onChangeColumnSort("asc", column?.sortOptions?.sortParam)
                }
              />
            }
            key={index}
          />
        );
      case "asc":
        return (
          <TableColumnHeaderItem
            content={
              <TableSortHeaderItem
                title={column?.title}
                icon={<LiaSortUpSolid size={15} className="text-primary" />}
                onClick={() =>
                  onChangeColumnSort("", column?.sortOptions?.sortParam)
                }
              />
            }
            key={index}
          />
        );
      default:
        return (
          <TableColumnHeaderItem
            key={index}
            content={
              <div className="flex flex-row items-center">{column?.title}</div>
            }
          />
        );
    }
  };

  return (
    <main className="flex flex-col">
      {/* move this out as a separate component */}
      {showFilters && filterFields && (
        <div className="mb-4">
          <div className="flex items-center justify-between space-x-4 rounded-lg bg-white p-3">
            <div className="flex-1">
              <FormFactory
                formFields={filterFields}
                onSubmit={setFilters}
                className="grid grid-cols-12 gap-4"
                formRef={formRef}
                autoSubmit={true}
              />
            </div>
            <TooltipWrapper
              text="Resetuj"
              button={
                <Button
                  type="button"
                  variant="secondary"
                  className={`${filterFields?.length > 4 ? "mt-6 self-start" : "self-end"}`}
                  onClick={() => {
                    formRef?.current?.setValues?.(filterQueries);
                    formRef?.current?.handleSubmit?.();
                  }}
                >
                  <GrPowerReset size={15} />
                </Button>
              }
            />
          </div>
        </div>
      )}

      <section className="w-full">
        <section className="mb-4 flex items-center justify-between">
          <div className="flex">
            {filterFields && (
              <div className="flex w-full items-center">
                <TooltipWrapper
                  button={
                    <div
                      onClick={() => {
                        setShowFilters(!showFilters);
                      }}
                      className="flex w-fit cursor-pointer items-center rounded-full border border-gray-200 bg-white px-2 py-1.5 pr-2 transition-all duration-300 hover:border-gray-300"
                    >
                      <GoChevronLeft
                        className={`mr-1 transition-all duration-300 ${showFilters ? "-rotate-90" : "rotate-90"} `}
                      />
                      <CiFilter />
                    </div>
                  }
                  text="Filteri"
                />

                <div className="flex flex-row space-x-2">
                  <span className="ml-2 w-full text-sm text-gray-500">
                    {paginationData?.total
                      ? `${paginationData.total} rezultat(a)`
                      : data?.data?.length
                        ? `${data.data.length} rezultat(a)`
                        : "Nema rezultata"}
                  </span>
                  <div className="flex-1 whitespace-nowrap text-sm text-gray-500">
                    {selectedRows?.length
                      ? `${selectedRows.length} od ${paginationData?.total} selektovano`
                      : selectAll
                        ? `${paginationData?.total} od ${paginationData?.total} selektovano.`
                        : ""}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex">
            {!hidePagination && data?.pagination ? (
              <TablePagination
                page={query?.page}
                totalPages={paginationData?.total_pages}
                perPage={query?.per_page}
                paginationMethods={paginationMethods}
              />
            ) : null}
          </div>
        </section>
        {data?.data?.length !== 0 && data?.data && customComponent}
        {tableHeaderContent}

        {Object.keys(data ?? {}).length === 0 || !data ? (
          <TableSkeleton />
        ) : data?.data?.length !== 0 && data?.data ? (
          <div className="flex flex-col overflow-x-auto bg-white">
            <section className="relative overflow-x-auto ">
              <table className="w-full table-auto text-left text-sm text-gray-500 rtl:text-right">
                <thead className="bg-gray-50 text-sm uppercase text-gray-700">
                  <tr>
                    {[
                      ...columnsData,
                      ...tableActionButtons({
                        actionButtons,
                        hideActions,
                        hideEdit,
                        hideDelete,
                        hidePrint,
                        onEdit,
                        onDelete,
                        onPrint,
                      }),
                    ].map((column: ITableColumn, index: number) =>
                      column?.renderHeader ? (
                        <TableColumnHeaderItem
                          key={index}
                          content={column?.renderHeader()}
                        />
                      ) : (
                        renderColumnHeader(column, index)
                      ),
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((singleData: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-100 bg-white text-sm hover:bg-gray-50"
                      >
                        {[
                          ...columnsData,
                          ...tableActionButtons({
                            actionButtons,
                            hideActions,
                            hideEdit,
                            hideDelete,
                            hidePrint,
                            onEdit,
                            onDelete,
                            onPrint,
                          }),
                        ].map((column: ITableColumn, indx: number) => (
                          <td key={indx} className="content-center px-6 py-3">
                            {column?.render ? (
                              column?.render(singleData, index)
                            ) : (
                              <p className="font-normal leading-none">
                                {TableUtils.handleColumnKeys(
                                  singleData,
                                  column?.key,
                                )}
                              </p>
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          </div>
        ) : (
          <div className="flex  items-center justify-center  rounded-lg border border-gray-200 p-4">
            <RxValueNone size={20} className="text-gray-600" />
            <p className="ml-1 text-sm text-gray-500">
              Nema zabeleženih podataka
            </p>
          </div>
        )}
      </section>
    </main>
  );
}

export default Table;
