import { useEffect, useState } from "react";
import { getSidebarRoutes } from "@/common/config/sidebar-routes";
import { useNavigate } from "react-router";
import logo from "../common/static/lss-logo.png";
import { SidebarChild, SidebarItemType } from "@/common/types/Sidebar";
import { useAuth } from "@/common/context/AuthProvider";
import Utils from "@/common/utils/Utils";
import mpLogo from "../common/static/mp-logo.png";
import mupLogo from "../common/static/mup-logo.png";
import { FiMenu } from "react-icons/fi";
import SidebarItem from "./SidebarItem";
import { Role } from "@/common/types/User";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";

const Sidebar: React.FC = () => {
  const [sidebarRoutes, setSidebarRoutes] = useState<SidebarItemType[]>([]);
  const { loginData } = useAuth();
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(window.screen.width > 500);

  const filterAllowedRoutes = (roles?: Role[]) => {
    const routes = getSidebarRoutes().filter((route: SidebarItemType) => {
      if (route?.children) {
        route.children = route?.children?.filter((childRoute: SidebarChild) =>
          Utils.hasPermission(roles, childRoute?.roles),
        );
      }
      return Utils.hasPermission(roles, route?.roles);
    });

    setSidebarRoutes(routes);
  };

  const onToggleSidebar = () => setToggleSidebar(!toggleSidebar);

  useEffect(() => {
    filterAllowedRoutes(loginData?.data?.roles);
  }, [loginData]);

  useEffect(() => {
    if (window.screen.width < 500) {
      setIsMobileView(true);
    }
  }, []);

  return (
    <div
      className={`xl:min-w-70 grid w-0 grid-rows-10 bg-sidebarBackground transition-all duration-300 md:w-80 ${isMobileView ? "fixed top-0 z-50  h-screen bg-sidebarBackground transition-all duration-300" : ""} ${toggleSidebar ? "left-0 w-4/5" : "-left-[200px]"}`}
    >
      <div
        className={`row-span-1 my-6 flex items-center ${isMobileView ? "justify-center" : "pl-7"}`}
      >
        <img
          onClick={() => navigate("/hunting-cards")}
          src={logo}
          className="h-12 w-auto cursor-pointer"
        />
      </div>
      <div className="row-span-8 flex flex-1 flex-col overflow-y-auto pt-6">
        <nav >
          <Accordion type="multiple">
          {sidebarRoutes?.map((route, index) => (
            <SidebarItem
              key={index}
              label={route?.label}
              route={route?.route}
              children={route?.children}
            />
          ))}
          </Accordion>
        </nav>
      </div>
      <div className="row-span-1 flex items-end justify-around pb-5">
        <img src={mpLogo} className="h-10 cursor-pointer" />
        <img src={mupLogo} className="h-10 cursor-pointer" />
      </div>
      <div
        onClick={onToggleSidebar}
        className="fixed left-0 top-0 flex h-16 w-16 items-center justify-center bg-primary text-white sm:hidden"
      >
        <FiMenu size={20} />
      </div>
    </div>
  );
};

export default Sidebar;
