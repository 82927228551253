import { SidebarChild, SidebarItemType } from "@/common/types/Sidebar";
import SidebarChildItem from "./SidebarChildItem";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";

const SidebarItem = ({ label, route, children }: SidebarItemType) => {
  return (
    <div >
      {route ? (
        <SidebarChildItem route={route} label={label} />
      ) : (
        <AccordionItem value={label}>
        <AccordionTrigger style={{backgroundColor: "#070d12", color: "#717891", borderColor: "#717891"}}>{label}</AccordionTrigger>
        <div className="flex flex-col">
          {children?.map((child: SidebarChild, index: number) => (
            <AccordionContent style={{backgroundColor: "#070d12", color: "#717891"}}>
              <SidebarChildItem
                route={child?.route}
                label={child?.label}
                icon={child?.icon}
                key={index}
              />
            </AccordionContent>
          ))}
        </div>
        </AccordionItem>
      )}
    </div>
  );
};

export default SidebarItem;
