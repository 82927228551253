import Table from "@/components/table/Table";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./hunting-card-orders-fields";
import { useEffect, useState } from "react";
import { HuntingCardOrdersService } from "@/common/API/HuntingCardOrdersService";
import { IHuntingOrders } from "@/common/types/HuntingOrders";
import { useNavigate } from "react-router";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import Utils from "@/common/utils/Utils";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import useDropdownData from "@/common/hooks/useDropdownData";
import { DividerHorizontalIcon } from "@radix-ui/react-icons";
import { DEFAULT_MAX_VERSION } from "tls";
import StatusTag from "@/components/StatusTag";
import { HuntingCardOrderStatus } from "@/common/enums/hunting";

const HuntingCardOrdersList = () => {
  const [data, setData] = useState<IHuntingOrders[]>([]);
  const [statusCards, setStatusCards] = useState<any>();
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { associations, organizations } = useDropdownData({
    hasAgency: true,
    query,
  });

  const { loginData, isUser } = useAuth();

  const hasAssociation =
    !!loginData?.data?.association ||
    Utils.hasPermission(loginData?.data?.roles, [ROLES.Agency]);

  const navigate = useNavigate();

  const getHuntingCardOrders = async () => {
    const res = await HuntingCardOrdersService.all(
      Utils.formatQueryString(query),
    );
    setData(res?.data);
    const inProgressCardCount = res?.data?.data?.reduce(
      (acc: any, order: any) => {
        if (order.status === HuntingCardOrderStatus.Sent) {
          return acc + order.hunters_count;
        }
        return acc;
      },
      0,
    );
    const inProgressCount = res?.data?.data?.filter(
      (order: any) => order.status === HuntingCardOrderStatus.Sent,
    ).length;

    const openCardCount = res?.data?.data?.reduce((acc: any, order: any) => {
      if (order.status === HuntingCardOrderStatus.Open) {
        return acc + order.hunters_count;
      }
      return acc;
    }, 0);
    const openCount = res?.data?.data?.filter(
      (order: any) => order.status === HuntingCardOrderStatus.Open,
    ).length;

    const acceptedCardCount = res?.data?.data?.reduce(
      (acc: any, order: any) => {
        if (order.status === HuntingCardOrderStatus.Accepted) {
          return acc + order.hunters_count;
        }
        return acc;
      },
      0,
    );
    const acceptedCount = res?.data?.data?.filter(
      (order: any) => order.status === HuntingCardOrderStatus.Accepted,
    ).length;

    const rejectedCardCount = res?.data?.data?.reduce(
      (acc: any, order: any) => {
        if (order.status === HuntingCardOrderStatus.Rejected) {
          return acc + order.hunters_count;
        }
        return acc;
      },
      0,
    );
    const rejectedCount = res?.data?.data?.filter(
      (order: any) => order.status === HuntingCardOrderStatus.Rejected,
    ).length;

    setStatusCards({
      inProgress: { cards: inProgressCardCount, orders: inProgressCount },
      open: { cards: openCardCount, orders: openCount },
      accepted: { cards: acceptedCardCount, orders: acceptedCount },
      rejected: { cards: rejectedCardCount, orders: rejectedCount },
    });
  };

  const onDelete = async (id?: string) => {
    await HuntingCardOrdersService.delete(id);
    getHuntingCardOrders();
  };

  useEffect(() => {
    getHuntingCardOrders();
  }, [query]);

  return (
    <PageLayout
      title="Zahtevi za lovne karte"
      showCreateButton={Utils.hasPermission(loginData?.data?.roles, [
        ROLES.User,
        ROLES.Agency,
      ])}
      cards={
        <div className="flex items-center space-x-2">
          <StatusTag
            type="in-progress"
            title={`Neodgovoreno: ${statusCards?.inProgress?.cards} Lovnih karata (${statusCards?.inProgress?.orders} zahteva)`}
          />
          <StatusTag
            type="open"
            title={`U doradi: ${statusCards?.open?.cards} Lovnih karata (${statusCards?.open?.orders} zahteva)`}
          />
          <StatusTag
            type="rejected"
            title={`Odbijeno: ${statusCards?.rejected?.cards} Lovnih karata (${statusCards?.rejected?.orders} zahteva)`}
          />
          <StatusTag
            type="accepted"
            title={`Odobreno: ${statusCards?.accepted?.cards} Lovnih karata (${statusCards?.accepted?.orders} zahteva)`}
          />
        </div>
      }
    >
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(hasAssociation, associations, organizations)}
        filterQueries={filterQueries}
        onEdit={(item) =>
          isUser
            ? navigate(`/hunting-card-orders/create/${item?.id}`)
            : navigate(`/hunting-card-orders/${item?.id}`)
        }
        onDelete={(id) => onDelete(id)}
        hideEdit={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.Moderator,
            ROLES.User,
            ROLES.Agency,
          ])
        }
        hideDelete={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.Moderator,
          ])
        }
        hidePrint={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
      />
    </PageLayout>
  );
};

export default HuntingCardOrdersList;
