import CONSTANTS from "@/common/config/constants";
import { HuntingCardOrderStatus } from "@/common/enums/hunting";
import { DropdownData } from "@/common/types/Form";
import { Organization } from "@/common/types/Reports";
import Utils from "@/common/utils/Utils";
import Dropdown from "@/components/forms/Dropdown";
import DropdownSearch from "@/components/forms/DropdownSearch";
import FileUpload from "@/components/forms/FileUpload";
import Input from "@/components/forms/Input";
import TextArea from "@/components/forms/Textarea";
import StatusTag from "@/components/StatusTag";
import Tag from "@/components/Tag";
import Text from "@/components/Text";
import TooltipWrapper from "@/components/TooltipWrapper";

export const tableColumns = [
  {
    title: "Broj Zahteva",
    dataIndex: "id",
    key: "id",
    sortOptions: {
      sortDirection: "",
      sortParam: "id",
    },
  },
  {
    title: "Regionalni savez",
    dataIndex: ["organization", "association", "name"],
    key: ["organization", "association", "name"],
    render: ({ organization }: { organization: Organization }) => {
      const a = CONSTANTS.huntingAssociations.find(
        (a) => a?.id === organization?.association?.id,
      );

      return organization?.association ? (
        <TooltipWrapper
          button={
            <div className="flex flex-row items-center space-x-1">
              <Tag title={a?.name} className={a?.className} />
            </div>
          }
          text={a?.fullName!}
        />
      ) : (
        <Text variant="table">-</Text>
      );
    },
  },
  {
    title: "Korisnik lovišta",
    dataIndex: "organization.name",
    key: ["organization", "name"],
    sortOptions: {
      sortDirection: "",
      sortParam: "organization.name",
    },
  },
  {
    title: "Broj LK",
    dataIndex: "hunters_count",
    key: "hunters_count",
  },
  {
    title: "Datum",
    dataIndex: "created_at",
    key: "created_at",
    render: ({
      created_at,
      updated_at,
    }: {
      created_at: string;
      updated_at: string;
    }) => (
      <Text variant="table">
        {Utils.convertTime(updated_at ? updated_at : created_at)}
      </Text>
    ),
    sortOptions: {
      sortDirection: "",
      sortParam: "created_at",
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: ({ status }: { status: number }) => {
      switch (status) {
        case HuntingCardOrderStatus.Open:
          return <StatusTag type="open" title="U doradi" />;
        case HuntingCardOrderStatus.Sent:
          return <StatusTag type="in-progress" title="Neodgovoreno" />;
        case HuntingCardOrderStatus.Rejected:
          return <StatusTag type="rejected" title="Odbijeno" />;
        case HuntingCardOrderStatus.Accepted:
          return <StatusTag type="accepted" title="Odobreno" />;
      }
    },
    sortOptions: {
      sortDirection: "",
      sortParam: "status",
    },
  },
];

export const filterFields = (
  hasAssociation?: boolean,
  associations?: DropdownData[],
  organizations?: DropdownData[],
) => {
  return [
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Broj zahteva",
        name: "id",
        placeholder: "Pretraži po broju zahteva",
        value: "",
        focus: true,
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Savez",
        name: "association_id",
        placeholder: "Izaberi savez/agenciju",
        options: associations ?? [],
        value: "",
        hide: hasAssociation,
      },
    },
    {
      controlType: DropdownSearch,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Korisnik lovišta/Agencija",
        name: "organization_id",
        placeholder: "Izaberi korisnika lovišta/agenciju",
        options: organizations ?? [],
        value: "",
        disabled: organizations?.length === 0,
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Status",
        name: "status",
        placeholder: "Izaberi status",
        options: CONSTANTS.huntingCardOrderStatus,
        value: "",
      },
    },
  ];
};



export const filterQueries = {
  id: "",
  association_id: "",
  organization_id: "",
  status: "",
  sort: "",
  order: "",
};

export const cardFilterFields = () => {
  return [
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Ime i prezime",
        name: "name",
        placeholder: "Pretraži po Imenu i prezimenu",
        value: "",
        focus: true,
      },
    },
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "JMBG / Broj Pasoša",
        name: "unique_identifier",
        placeholder: "Pretraži po JMBG-u / Broju Pasoša",
        value: "",
        focus: true,
      },
    },
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Broj lovne karte",
        name: "card_number",
        placeholder: "Pretraži po Broju lovne karte",
        value: "",
        focus: true,
      },
    },
  ]
}

export const cardFilterQueries = {
 name: "",
 unique_identifier: "",
 card_number: ""
}

export const huntingCardOrderAdminEditFields = [
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6 lg:col-span-3",
    options: {
      label: "Broj zahteva",
      name: "id",
      placeholder: "",
      disabled: true,
      value: "",
    },
  },
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6 lg:col-span-3",
    options: {
      label: "Podnosilac zahteva",
      name: "organization",
      placeholder: "",
      disabled: true,
      value: "",
    },
  },
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6 lg:col-span-3",
    options: {
      label: "Zahtev podneo",
      name: "user_name",
      placeholder: "",
      disabled: true,
      value: "",
    },
  },
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6 lg:col-span-3",
    options: {
      label: "Regionalni/Pokrajinski savez",
      name: "association",
      placeholder: "",
      disabled: true,
      value: "",
    },
  },
  {
    controlType: FileUpload,
    className: "col-span-12 md:col-span-6 lg:col-span-4",
    options: {
      label: "Dokument izjave",
      name: "statement",
      value: "",
      disabled: true,
    },
  },
  {
    controlType: FileUpload,
    className: "col-span-12 md:col-span-6 lg:col-span-4",
    options: {
      label: "Dokument ovlašćenja",
      name: "authorization",
      value: "",
      disabled: true,
    },
  },
  {
    controlType: FileUpload,
    className: "col-span-12 md:col-span-6 lg:col-span-4",
    options: {
      label: "Dokument uplatnice",
      name: "payment_slip",
      value: "",
      disabled: true,
    },
  },
  {
    controlType: TextArea,
    className: "col-span-12 md:col-span-6",
    options: {
      label: "Napomena korisnika",
      name: "user_note",
      placeholder: "",
      disabled: true,
      value: "",
    },
  },
  {
    controlType: TextArea,
    className: "col-span-12 md:col-span-6",
    options: {
      label: "Napomena administratora",
      name: "admin_note",
      placeholder: "",
      value: "",
    },
  },
];

export const huntingCardOrderCreateFields = (isOrderSent?: boolean) => {
  return [
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Broj zahteva",
        name: "id",
        placeholder: "Broj zahteva će biti generisan",
        disabled: true,
        value: "",
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Podnosilac zahteva",
        name: "organization",
        placeholder: "Podnosilac zahteva",
        disabled: true,
        value: "",
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Regionalni/Pokrajinski savez",
        name: "association",
        placeholder: "Regionalni/Pokrajinski savez",
        disabled: true,
        value: "",
      },
    },
    {
      controlType: FileUpload,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Izjava",
        name: "statement",
        accept: "image/*, .pdf",
        disabled: isOrderSent,
      },
    },
    {
      controlType: FileUpload,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Ovlašćenje",
        name: "authorization",
        accept: "image/*, .pdf",
        disabled: isOrderSent,
      },
    },
    {
      controlType: FileUpload,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Uplatnica",
        name: "payment_slip",
        accept: "image/*, .pdf",
        disabled: isOrderSent,
      },
    },
    {
      controlType: TextArea,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Napomena korisnika",
        name: "user_note",
        placeholder: "Napomena korisnika",
        value: "",
        disabled: isOrderSent,
      },
    },
  ];
};
