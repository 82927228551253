import { HuntingCardOrdersService } from "@/common/API/HuntingCardOrdersService";
import { HunterData, IHuntingOrder } from "@/common/types/HuntingOrders";
import Table from "@/components/table/Table";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router";
import {
  huntingCardOrderAdminEditFields,
  cardFilterFields,
  filterQueries,
  cardFilterQueries,
} from "./hunting-card-orders-fields";
import FormFactory from "@/components/forms/FormFactory";
import { HuntingCardOrderStatus } from "@/common/enums/hunting";
import PageLayout from "@/components/layout/PageLayout";
import { Button } from "@/components/ui/button";
import { IoCheckboxOutline } from "react-icons/io5";
import { RiArrowGoBackFill } from "react-icons/ri";
import { RxValueNone } from "react-icons/rx";
import { createHunterFields, tableColumns } from "./hunter-fields";
import { FormikProps } from "formik";
import Utils from "@/common/utils/Utils";
import Modal from "@/components/Modal";
import { CreateHunterSchema } from "@/common/schemas/Hunting";
import { GrDocumentDownload } from "react-icons/gr";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import ConfirmationPopover from "@/components/ConfirmationPopover";
import { CgTrash } from "react-icons/cg";
import { useQuery } from "@/common/hooks/useQuery";

const HuntingCardOrdersAdminEdit = () => {
  const [data, setData] = useState<IHuntingOrder>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [hunter, setHunter] = useState<HunterData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries: cardFilterQueries,
  });

  const { loginData } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<IHuntingOrder>>(null);
  const hunterEditFormRef = useRef<FormikProps<HunterData>>(null);

  const getHuntingCardData = async () => {
    const res = await HuntingCardOrdersService.one(
      id,
      Utils.formatQueryString(query),
    );

    const data = res?.data?.data;

    formRef.current?.setValues({
      ...data,
      user_name: data?.user?.name,
      association: data?.organization?.association?.name ?? "",
      organization: data?.organization?.name,
      user_note: data?.user_note ?? "",
      admin_note: data?.admin_note ?? "",
    });

    setData(res?.data?.data);
  };

  useEffect(() => {
    getHuntingCardData();
  }, [query]);

  const onDecline = async ({ admin_note }: { admin_note: string }) => {
    await HuntingCardOrdersService.return({
      id,
      admin_note,
    });

    navigate("/hunting-card-orders");
  };

  const onAccept = async () => {
    await HuntingCardOrdersService.accept(id);

    navigate("/hunting-card-orders");
  };

  const onPrint = async (hunter: HunterData) => {
    setIsLoading(true);
    const response = await HuntingCardOrdersService.getHunterPdf(
      id,
      hunter?.id,
    );

    Utils.openDocument(response?.data?.data);
    setIsLoading(false);
  };

  const onEditHunter = async (data?: HunterData) => {
    await HuntingCardOrdersService.updateHunter(id, hunter?.id, data);
    setOpenEditModal(false);
    getHuntingCardData();
  };

  const onDeleteHunter = async (hunterId?: string) => {
    await HuntingCardOrdersService.deleteHunter(id, hunterId);
    getHuntingCardData();
  };

  const onDocumentDownload = async () => {
    setIsLoading(true);
    const response = await HuntingCardOrdersService.getAllHunterPdf(id);

    Utils.openDocument(response?.data?.data);
    setIsLoading(false);
  };

  const onDelete = async () => {
    await HuntingCardOrdersService.delete(id);
    navigate(`./../`);
  };

  return (
    <PageLayout
      backUrl="./../"
      title="Zahtev za lovnu kartu"
      buttons={
        <>
          <Button
            onClick={onAccept}
            hide={data?.status === HuntingCardOrderStatus.Accepted}
            isLoading={isLoading}
          >
            Odobri
            <IoCheckboxOutline className="ml-3" />
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => formRef?.current?.handleSubmit()}
            hide={data?.status === HuntingCardOrderStatus.Accepted}
            isLoading={isLoading}
          >
            Vrati na doradu
            <RiArrowGoBackFill className="ml-3" />
          </Button>
          <ConfirmationPopover
            title="Da li ste sigurni? Zahtev će biti obrisan."
            onDelete={onDelete}
            button={
              <div>
                <Button
                  variant="secondary"
                  isLoading={isLoading}
                  hide={
                    !(
                      data?.status === HuntingCardOrderStatus.Open ||
                      data?.status === HuntingCardOrderStatus.Sent
                    )
                  }
                >
                  Obriši zahtev <CgTrash className="ml-3" />
                </Button>
              </div>
            }
          />
        </>
      }
    >
      <div className="space-y-4">
        <FormFactory
          formFields={huntingCardOrderAdminEditFields}
          onSubmit={onDecline}
          formRef={formRef}
        />

        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <div className="text-md">Lista dodatih lovaca</div>
            <Button
              variant="secondary"
              onClick={onDocumentDownload}
              isLoading={isLoading}
            >
              Preuzmi dokument
              <GrDocumentDownload className="ml-3 text-text" />
            </Button>
          </div>
          <Table
            data={{ data: data?.hunters }}
            columns={tableColumns(data?.status)}
            filterFields={cardFilterFields()}
            filterQueries={cardFilterQueries}
            onPrint={onPrint}
            onEdit={(data) => {
              setHunter(data);
              setOpenEditModal(true);
            }}
            onDelete={(id) => onDeleteHunter(id)}
            hideDelete={data?.status === HuntingCardOrderStatus?.Accepted}
            query={query}
            setQuery={setQuery}
            setFilters={setFilters}
          />
          {/* {data?.hunters?.length === 0 ? (
            <div className="flex  items-center justify-center  rounded-lg border border-gray-200 p-4">
              <RxValueNone size={20} className="text-gray-600" />
              <p className="ml-1 text-xs text-gray-500">Nema dodatih lovaca</p>
            </div>
          ) : (
            <Table
              data={{ data: data?.hunters }}
              columns={tableColumns(data?.status)}
              filterFields={cardFilterFields()}
              filterQueries={cardFilterQueries}
              onPrint={onPrint}
              onEdit={(data) => {
                setHunter(data);
                setOpenEditModal(true);
              }}
              onDelete={(id) => onDeleteHunter(id)}
              hideDelete={data?.status === HuntingCardOrderStatus?.Accepted}
              query={query}
              setQuery={setQuery}
              setFilters={setFilters}
            />
          )} */}
        </div>
      </div>
      <Modal
        open={openEditModal}
        setOpen={setOpenEditModal}
        headerTitle="Izmeni lovca"
        buttonTitle="Izmeni"
        onSubmit={() => hunterEditFormRef?.current?.handleSubmit()}
        content={
          <FormFactory
            formFields={createHunterFields(hunter)}
            onSubmit={(data) => onEditHunter(data)}
            formRef={hunterEditFormRef}
            validationSchema={CreateHunterSchema}
          />
        }
      />
    </PageLayout>
  );
};

export default HuntingCardOrdersAdminEdit;
